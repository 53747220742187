<template>
  <div id="imagen">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card card-body border">
              <h4 class="card-title text-primary">Encabezado App Mapas</h4>
              <div class="card card-body">
                <img
                  v-if="evento.img != null"
                  v-bind:src="evento.img"
                  class="mx-auto"
                  style="max-width: 360px"
                />
                <div v-else class="text-center">
                  <i class="fa fa-info-circle"></i> No se ha agregado una
                  imagen.
                </div>
                <hr />
                <h6 class="font-weight-bold">Actualizar imagen</h6>
                <div class="form-group">
                  <input
                    @change="onFileChange"
                    type="file"
                    class="form-control"
                  />
                </div>
                <div>
                  <button
                    v-on:click="upload"
                    :disabled="!hay_archivo"
                    class="btn btn-success btn-sm"
                  >
                    <i class="fa fa-upload"></i> Actualizar imagen
                  </button>
                </div>
              </div>
            </div>
            <div class="card card-body mt-2 border">
              <h4 class="card-title text-primary">Encabezado Taqui Shop</h4>
              <div class="card card-body">
                <img
                  v-if="evento.banner != null"
                  v-bind:src="evento.banner"
                  class="mx-auto"
                  style="max-width: 360px"
                />
                <div v-else class="text-center">
                  <i class="fa fa-info-circle"></i> No se ha agregado una
                  imagen.
                </div>
                <hr />
                <h6 class="font-weight-bold">Actualizar</h6>
                <div class="form-group">
                  <input
                    @change="onFileChangeBanner"
                    type="file"
                    class="form-control"
                  />
                </div>
                <div>
                  <button
                    v-on:click="upload_banner"
                    :disabled="!hay_archivo_banner"
                    class="btn btn-success btn-sm"
                  >
                    <i class="fa fa-upload"></i> Actualizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Imagen",
  metaInfo: {
    title: "Imagen del evento - Taqui",
  },
  data() {
    return {
      loading: true,
      loading_upload: false,
      loading_upload_banner: false,
      id_evento: null,
      imagen: null,
      banner: null,
      file: [],
      file_banner: [],
      hay_archivo: false,
      hay_archivo_banner: false,
      msg_upload: {
        type: null,
        text: null,
      },
    };
  },
  props: {
    evento: Object,
  },
  methods: {
    onFileChange(e) {
      console.log(e);
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.hay_archivo = false;
        return;
      }
      this.file = files;
      this.hay_archivo = true;
    },
    onFileChangeBanner(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.hay_archivo_banner = false;
        return;
      }
      this.file_banner = files;
      this.hay_archivo_banner = true;
    },
    upload: function () {
      this.hay_archivo = false;
      this.loading_upload = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var formData = new FormData();
      formData.append("files", this.file[0]);
      formData.append("token", token_session);
      formData.append("id_evento", this.evento.id_evento);
      const vm = this;
      axios
        .post(this.$base_url + "upload/imagen_evento", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status) {
            vm.hay_archivo = false;
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Imagen",
              text: "Se ha actualizado la imagen del evento.",
            });
            vm.$emit("get_evento");
            vm.loading_upload = false;
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Imagen",
              text: response.data.message,
            });
            vm.loading_upload = false;
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Imagen",
            text: error,
          });
          console.log(error);
          vm.loading_upload = false;
        });
    },
    upload_banner: function () {
      this.hay_archivo_banner = false;
      this.loading_upload_banner = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var formData = new FormData();
      formData.append("files", this.file_banner[0]);
      formData.append("token", token_session);
      formData.append("id_evento", this.evento.id_evento);
      const vm = this;
      axios
        .post(this.$base_url + "upload/imagen_evento_shop", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status) {
            vm.hay_archivo_banner = false;
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Imagen",
              text: "Se ha actualizado la imagen del para Taqui Shop.",
            });
            vm.$emit("get_evento");
            vm.loading_upload_banner = false;
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Imagen",
              text: response.data.message,
            });
            vm.loading_upload_banner = false;
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Imagen",
            text: error,
          });
          console.log(error);
          vm.loading_upload_banner = false;
        });
    },
  },
};
</script>